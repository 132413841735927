<template>
  <div class="station-detail" ref="mapStation">
    <button class="station-detail__back-button" @click="stationDetailClose()"><ArrowLeftIcon /> Back</button>
    <div class="station-detail__wrapper">
      <button class="map-close-button" @click="stationDetailClose()"><CloseIcon /></button>
      <p
        v-if="selectedStation.facility === 'SOLAR' && !selectedStation.comingSoon"
        class="panel panel--bg station-detail__solar-heading"
      >
        <SolarWhite class="image" /> 100% Solar Powered
      </p>
      <div class="panel panel--grey" v-if="!selectedStation.comingSoon">
        <img src="@/assets/images/LocateCharger/electric-bolt.webp" alt="" />
        <div>
          <div class="arrow-link link">
            <router-link :to="{ name: 'mobile-app-en' }" class=""
              >Download the app <arrow class="arrow"></arrow
            ></router-link>
          </div>
          <p>View up-to-date pricing and real time charger availability in the mobile app</p>
        </div>
      </div>
      <div class="panel station-detail__meta">
        <div>
          <h2 class="headline4">
            {{ selectedStation.name }}
            <span class="sr-only"> station details</span>
          </h2>
          <p class="station-address">
            {{ selectedStation.address }}, {{ selectedStation.city }}, {{ selectedStation.state }}
            {{ selectedStation.zipCode }}
          </p>
          <p v-if="selectedStation.description">{{ selectedStation.description }}</p>
        </div>
        <div>
          <button class="directions" @click="openDirections" aria-label="Get direction">
            <span class="sr-only">Directions</span>
            <IconDirections class="img" />
          </button>
        </div>
      </div>
      <p v-if="selectedStation.comingSoon">Coming Soon</p>
      <template v-if="!selectedStation.comingSoon">
        <div class="panel station-detail__chargers" v-if="isVWHeadOffice">
          <h2 class="map-panel-headline">Hours <span class="sr-only"> Hours details</span></h2>
          <table>
            <tr>
              <td class="days pb-10">Monday - Friday:</td>
              <td class="pb-10">6pm - 6am</td>
              <td class="pb-10">Open</td>
            </tr>
            <tr>
              <td class="days">Saturday - Sunday:</td>
              <td>24hr</td>
              <td>Open</td>
            </tr>
          </table>
        </div>
        <div class="panel station-detail__chargers">
          <h3 class="map-panel-headline">Chargers at station #{{ selectedStation.id }}</h3>
          <template v-if="isVWHeadOffice"
            ><div class="number-of-charger">
              <h5>50 L2 chargers at this station</h5>
            </div>
            <ul>
              <li class="panel panel--bg">
                <div class="connectors">
                  <StationDetailConnector
                    :id="filteredStationTypes[0].connectors[0].id"
                    :connector="filteredStationTypes[0].connectors[0]"
                    :isVWHeadOffice="isVWHeadOffice"
                  />
                </div>
              </li></ul
          ></template>
          <template v-else>
            <ul>
              <li
                class="panel panel--bg"
                v-for="(charger, $index) in filteredStationTypes"
                :key="$index"
                :id="charger.id"
              >
                <strong>{{ charger.id.split('-').pop() }}</strong>
                <div class="connectors">
                  <StationDetailConnector
                    v-for="(connector, $index) in charger.connectors"
                    :key="$index"
                    :id="connector.id"
                    :connector="connector"
                  />
                </div>
              </li>
            </ul>
          </template>
          <button
            class="view-all button-link link"
            style="font-size: 13px"
            @click="toggleStationConnectors"
            v-if="this.selectedStation.stationTypes && this.selectedStation.stationTypes.length > 4 && !isVWHeadOffice"
          >
            {{ viewStationConnectors ? 'View less' : 'View all' }}
          </button>
        </div>

        <div v-if="selectedStation.facility === 'SOLAR'" class="panel station-detail__solar-disclaimer">
          <SolarBlue class="image" />
          <p>
            <strong>The chargers at this station are entirely solar powered.</strong> When you arrive, check the power
            indicator to ensure adequate charge.
          </p>
        </div>

        <div class="panel station-detail">
          <router-link
            class="connector-type button-link link"
            :to="{ name: 'getting-started-en' }"
            rel="noopener"
            target="_blank"
          >
            What connector type does my vehicle use?
          </router-link>
        </div>

        <div class="panel station-detail__pricing">
          <h3 class="map-panel-headline">Pricing</h3>
          <div v-if="selectedStation.isCommercialChargerLocation" class="panel panel--bg">
            <h4 class="headline5">Pricing may vary</h4>
            <p>Please check the charger’s display for pricing details.</p>
          </div>
          <template v-if="isVWHeadOffice">
            <p>Pricing is available in the app</p>
          </template>
          <template v-else>
            <p>
              Pricing for DC fast charging is determined by charger location, your plan, and, for per-minute locations,
              the maximum power level your vehicle can accept. Real-time pricing is available in the app or at the
              charger.
            </p>
            <router-link
              class="connector-type button-link link"
              :to="{ name: 'pricing-en' }"
              rel="noopener"
              target="_blank"
            >
              Learn more about pricing information
            </router-link>
          </template>
        </div>
        <div class="panel map-panel-headline">
          Download the app
          <div class="appStore">
            <a
              href="https://itunes.apple.com/us/app/electrify-america/id1458030456?mt=8"
              rel="noopener"
              target="_blank"
            >
              <img
                loading="lazy"
                :src="require('@/assets/images/LocateCharger/app-store.webp')"
                alt="Download on the App Store"
              /> </a
            ><br />
            <a
              href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
              rel="noopener"
              target="_blank"
            >
              <img
                loading="lazy"
                :src="require('@/assets/images/LocateCharger/play.webp')"
                alt="Get it on Google Play"
              />
            </a>
          </div>
        </div>
      </template>
    </div>
    <template v-if="selectedStation.comingSoon">
      <div class="station-detail__upsell">
        <router-link :to="{ name: 'mobile-app-en' }" class="link"
          >Download the app for real-time charger availability<arrow class="arrow"></arrow>
        </router-link>

        <p>Create an account within the Electrify America app to see charger availability</p>
        <img width="100%" src="@/assets/images/Map/Upsell_Image@3x.png" alt="" />
      </div>
    </template>
  </div>
</template>

<script>
import arrow from '@/assets/images/Global/arrow.svg';
import ArrowLeftIcon from '@/assets/images/Map/arrow-left.svg';
import CloseIcon from '@/assets/images/Map/close-icon.svg';
import IconDirections from '@/assets/images/Map/Icon_Directions.svg';
import SolarBlue from '@/assets/images/Map/SolarIcon_Blue.svg';
import SolarWhite from '@/assets/images/Map/SolarIcon_White.svg';
import StationDetailConnector from '@/components/LocateCharger/StationDetailConnector.vue';

export default {
  name: 'StationDetail',
  props: ['selectedStation', 'isSelectedStationDetailsLoaded'],
  components: {
    arrow,
    ArrowLeftIcon,
    CloseIcon,
    IconDirections,
    SolarBlue,
    SolarWhite,
    StationDetailConnector,
  },
  data() {
    return {
      passPlusSessionFee: 0,
      passSessionFee: 0,
      viewStationConnectors: false,
    };
  },
  methods: {
    openDirections() {
      if (this.selectedStation && this.selectedStation.position) {
        const lat = encodeURIComponent(this.selectedStation.position.lat);
        const lng = encodeURIComponent(this.selectedStation.position.lng);
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`, '_blank');
      }
    },
    stationDetailClose() {
      this.$emit('stationDetailClose');
    },
    toggleStationConnectors() {
      this.viewStationConnectors = !this.viewStationConnectors;
    },
  },
  computed: {
    filteredStationTypes() {
      if (this.viewStationConnectors) {
        return this.selectedStation.stationTypes;
      }
      return this.selectedStation?.stationTypes?.slice(0, 4);
    },
    isVWHeadOffice() {
      return this.selectedStation.address === '1950 Opportunity Way' && this.selectedStation.city === 'Reston';
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  display: flex;
  flex-direction: column;
  margin: 0 0 18px 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--bg {
    background-color: #eef0f4;
    border-radius: $global-border-radius;
    padding: 8px 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &--grey {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    flex-basis: 200px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1);

    border-radius: $global-border-radius;
    padding: 8px 16px;
    margin-bottom: 18px;
    padding-top: 20px;
    img {
      width: 30px;
      height: 85%;
      margin-right: 20px;
    }
    p {
      font-weight: $fw-regular;
    }
    .link {
      text-transform: uppercase;
      text-decoration: none;
    }
    .arrow-link {
      display: flex;
      align-items: center;
      &:hover {
        color: $c-focus;
      }
    }
  }
  .appStore {
    img {
      margin-top: 0;
      height: 50px;
      &:last-child {
        margin-top: 15px;
      }
    }
  }
}

.station-detail {
  position: relative;
  width: 100%;

  &__back-button {
    display: none;

    @media (min-width: 750px) {
      align-items: center;
      appearance: unset;
      background: transparent;
      border: 0;
      color: $c-gray-dark;
      display: flex;
      font-size: 12px;
      height: fit-content;
      left: 18px;
      margin-top: 0;
      position: absolute;
      text-transform: uppercase;
      top: 18px;
      z-index: 90;

      svg {
        background: #eaedf2;
        border-radius: 50%;
        height: 24px;
        margin-right: 12px;
        padding: 4px;
        width: 24px;
      }
    }
  }
  &__chargers {
    overflow: auto;

    strong {
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
    }

    .view-all {
      appearance: unset;
      background: transparent;
      border: 0;
      margin: 12px auto 5px auto;
      padding: 5px;
      text-align: center;
    }
  }

  &__pricing {
    .pricing-table {
      border-collapse: separate;
      border-spacing: 10px 6px;
      font-size: 1.125rem;

      td {
        vertical-align: top;
      }

      th {
        color: $c-gray-dark;
        font-size: 12px;
        font-weight: $fw-medium;
      }

      th[scope='col'] {
        border-bottom: 1px solid #c9cfdc;
      }

      th[scope='row'] {
        text-align: left;

        h4 {
          color: #0e193f;
          font-size: 1.125rem;
          font-weight: $fw-medium;
        }
      }
    }
  }

  &__meta {
    flex-direction: row;
    justify-content: space-between;
  }

  &__solar-disclaimer {
    position: relative;

    .image {
      position: absolute;
      height: 20px;
      width: 20px;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      font-weight: $fw-medium;
      margin: 0 0 0 30px;
    }
  }

  &__solar-heading {
    background-color: $c-border-blue;
    color: $c-primary-background;
    flex-direction: row;
    font-weight: $fw-medium;

    .image {
      height: 24px;
      margin: 0 8px 0 0;
      width: 24px;
    }
  }

  &__wrapper {
    background-color: $c-primary-background;
    margin-top: calc(40vh);
    padding: 20px;
    position: relative;

    @media (min-width: 750px) {
      height: fit-content;
      margin-top: 0;
      padding: 50px;
      padding-top: 70px;
      padding-bottom: 20px;
    }

    h2 {
      font-weight: $fw-medium;
    }

    p {
      font-size: 14px;
    }

    .directions {
      appearance: unset;
      background: transparent;
      border: 0;
      margin-left: 10px;

      .img {
        height: 40px;
        width: 40px;
      }
    }
  }
  &__upsell {
    background: #f5f5f5;
    padding: 40px 28px;
    .link {
      font-size: 16px;
      text-transform: uppercase;
    }
    p {
      margin-bottom: 0;
    }
    @media (min-width: 750px) {
      padding: 20px 60px;
    }
  }
  .connector-type.link,
  .view-all.link {
    text-transform: inherit;
    font-size: 12px;
    text-decoration: none;
  }
  .number-of-charger {
    background: $c-primary-light;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px 20px;
    h5 {
      color: $c-primary-background;
      font-size: 16px;
    }
  }
  table {
    .days {
      font-weight: $fw-medium;
    }
    .pb-10 {
      padding-bottom: 20px !important;
    }
    td {
      font-size: 14px;
    }
  }
}
</style>
